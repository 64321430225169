<template>
  <b-card>
    <b-alert
      variant="secondary"
      show
      class="mb-2"
    >
      <div class="alert-body">
        <span>
          Please create a new voucher if you want to change the amount, start date or end date.
        </span>
      </div>
    </b-alert>
    <FormBuilder
      :diff-only="false"
      label="Voucher"
      update
      :data="$store.state.voucher.detailData"
      :fields="fields"
      :loading-detail="$store.state.voucher.loadingDetail"
      :loading-submit="$store.state.voucher.loadingSubmit"
      @load="loadData"
      @submit="submitData"
      @reset="resetData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
  BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAlert,
    FormBuilder,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'name',
          label: 'Name',
          required: true,
          notes: 'You can use the event name or creator name.',
        },
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('voucher/getDetail', id)
    },
    submitData(id, data, callback) {
      this.$store.dispatch('voucher/update', { id, data })
        .then(callback)
    },
    resetData() {
      return this.$store.commit('voucher/SET_DETAIL_DATA', undefined)
    },
  },
}
</script>

    <style scoped>

    </style>
